import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import receiptIcon from '../assets/icons/payment/bill.png'
import { clearCart } from '../store/cartSlice';

const Success = () => {
  // Retrieve the order data from the Redux store
  const order = useSelector((state) => state.order);
  const totalPrice = order.items.reduce((total, item) => total + item.price * item.quantity, 0);
  const navigate = useNavigate(); // Get the navigate function
  const dispatch = useDispatch();

  const handleBackToMenu = () => {
    dispatch(clearCart())
    navigate(`/${order.restaurant}`);
  }

  return (
    <div>
      <div className="relative pb-[23%]">
        <img
          className="absolute inset-0 object-cover w-full h-full"
          src="https://www.sheknows.com/wp-content/uploads/2020/06/best-picnic-food-cover-amazon.jpg"
          alt="Cover"
        />
        {/* Black circle with white border */}
        <div className="absolute -bottom-8 left-1/2 transform -translate-x-1/2 bg-black rounded-full border-4 border-white w-16 h-16 flex items-center justify-center">
          {/* Checkmark inside the circle */}
          <svg
            className="w-8 h-8 text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 13l4 4L19 7"
            />
          </svg>
        </div>
      </div>
      <div className="p-5">
        <h2 className="flex justify-center mt-8 text-2xl font-bold">Thank You, {order.name}!</h2>
        <div>
          <h3 className="mt-10 text-xl font-semibold mb-4">Your order</h3>
          <div className="flex justify-between font-semibold text-lg mb-2">
            <div className="flex">
              <img
                src={receiptIcon} // Replace with the path to your cash icon
                alt="Cash Icon"
                className="mr-2 h-6 w-6" // Adjust the size as needed
              />
              Order for Table {order.tableNumber}
            </div>
            <p>
              ${totalPrice.toFixed(2)} {/* Added .toFixed(2) to format as currency */}
            </p>
          </div>
          <div>
            {order.items.map(item => (
              <div key={item._id} className="flex justify-between mb-2">
                <div>
                  <span className="ml-8">{item.name}</span>
                  <span className="ml-3">x{item.quantity}</span>
                </div>
                <span className="">${(item.price * item.quantity).toFixed(2)}</span>
              </div>
            ))}
          </div>
        </div>

        {/* Back to Menu button */}
        <div className="fixed bottom-0 left-0 w-full bg-white px-4 py-3 shadow-md border">
          <button
            className="bg-indigo-800 text-white font-bold py-3 px-4 rounded w-full font-semibold"
            onClick={handleBackToMenu}
          >
            Back to Menu
          </button>
        </div>
      </div>
    </div>
  );
};

export default Success;




