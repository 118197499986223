import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CategoryList from '../components/CategoryList';
import { getCategoriesByRestaurant, getRestaurant } from '../services/api';
import { useSelector, useDispatch } from 'react-redux';
import { setTableNumber } from '../store/tableNumberSlice';

const Home = ({ restaurant, restaurantDisplayName }) => {
  const [categories, setCategories] = useState([]);
  const [coverPhotos, setCoverPhotos] = useState([]);
  const [displayName, setDisplayName] = useState('');
  const [logo, setLogo] = useState('');
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [localTableNumber, setLocalTableNumber] = useState('');
  const [isSelectPressed, setIsSelectPressed] = useState(false);
  const tableNumber = useSelector(state => state.tableNumber);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const scannedTableNumber = queryParams.get('number');

  useEffect(() => {
    document.title = `${restaurantDisplayName} - Explore Our Menu!`;
    const metaTag = document.querySelector('meta[name="description"]');
    metaTag.setAttribute('content', `${restaurantDisplayName} - Explore Our Menu!`);
    if (scannedTableNumber) {
      dispatch(setTableNumber(scannedTableNumber));
    }

    // Fetch restaurant data
    getRestaurant(restaurant)
      .then(data => {
        setCoverPhotos(data.coverPhotos);
        setDisplayName(data.displayName);
        setLogo(data.logo);
      })
      .catch(error => {
        console.error('Error fetching restaurant data:', error);
      });

    // Fetch categories
    getCategoriesByRestaurant(restaurant).then(data => setCategories(data));
  }, [restaurant]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlide(prevSlide => (prevSlide + 1) % coverPhotos.length);
    }, 3500); // Change the interval time as needed

    return () => clearInterval(intervalId);
  }, [coverPhotos.length]);
  
  const handleSelectCategory = category => {
    navigate(`/${restaurant.toLowerCase()}/dine-in/${category.toLowerCase()}`);
  };
  

  const openModal = () => {
    setIsModalOpen(true);
    document.body.style.overflow = 'hidden'; // Disable scrolling
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsSelectPressed(false);
    document.body.style.overflow = 'auto'; // Enable scrolling
  };

  const handleTableNumberChange = event => {
    setLocalTableNumber(event.target.value);
  };

  const handleSubmitTableNumber = () => {
    dispatch(setTableNumber(localTableNumber));
    setIsSelectPressed(!isSelectPressed);
    navigate(`/${restaurant.toLowerCase()}`);
    closeModal();
  };

  const handleSelectButton = () => {
    // Toggle the state when the button is pressed
    setIsSelectPressed(!isSelectPressed);
    openModal();
  };

  return (
    <div>
      <header className="fixed top-0 w-full bg-yellow-700 z-10">
        <nav className="mx-auto py-3.5 flex items-center">
          <img src={logo} className="h-8 w-auto ml-4"/>
          <div className="flex justify-center absolute right-0 left-0">
          <h1 className="text-white text-xl font-bold ml-4 khmer-text">{displayName}</h1>
          </div>
        </nav>
      </header>
      <div className="pt-14 max-w-screen-lg mx-auto max-h-screen">
        {/* Cover Photo Slider */}
        <div className="relative pb-[45%] overflow-hidden">
          {coverPhotos.map((coverPhoto, index) => (
            <img
              key={index}
              className={`absolute inset-0 object-cover w-full h-full transform transition-transform ${
                index === currentSlide ? 'translate-x-0' : 'translate-x-full'
              }`}
              src={coverPhoto}
              alt={`Cover Photo ${index + 1}`}
            />
          ))}
        </div>
        <div className="flex-grow">
        <CategoryList categories={categories} onSelectCategory={handleSelectCategory} />
        </div>
        {categories.length > 0 && (
        <div className="text-center text-sm pb-10">
          Powered by Dineboo
        </div>
        )}
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-end justify-center z-50">
          <div
            className="fixed inset-0 bg-gray-800 opacity-70"
            onClick={closeModal} // Close modal when clicking on the overlay
          ></div> {/* Gray overlay */}
          <div className="container mx-auto z-50"> {/* Updated z-index */}
            <div className="bg-white rounded-t-2xl border border-gray-300 p-4">
              <div className="flex items-center justify-between mb-2.5">
                <button className="text-black" onClick={closeModal}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M10 19l-7-7m0 0l7-7m-7 7h18"
                    />
                  </svg>
                </button>
                <p className="text-lg font-semibold">Where are you seated?</p>
                <button className="text-black" onClick={closeModal}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <hr className="mt-2 mb-2 -mx-4" /> {/* Span the whole container */}
              <p className="text-sm mt-2.5 font-semibold">Table Number</p>
              <input
                type="text"
                placeholder="Table Number"
                value={localTableNumber}
                onChange={handleTableNumberChange}
                className="w-full px-3 py-3 mt-1 rounded-md bg-gray-200 focus:outline-none focus:border focus:border-indigo-800 focus:bg-white"
              />
              <div className="mt-4 flex justify-center">
                <button
                  className="bg-blue-800 text-white w-full py-3 rounded-md font-semibold"
                  onClick={handleSubmitTableNumber}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;




