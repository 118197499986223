import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    items: [], // Initialize with an empty array
  },
  reducers: {
    addToCart: (state, action) => {
      // Add the menu item as a separate cart item with its own quantity
      const nextCartItemId = state.items.length; // This gives you the next available ID
      state.items.push({ ...action.payload, cartItemId: nextCartItemId, quantity: action.payload.quantity });
    },
    increaseQuantity: (state, action) => {
      // Increase the quantity of a specific cart item
      const cartItem = state.items.find(item => item.cartItemId === action.payload);

      if (cartItem) {
        cartItem.quantity += 1;
      }
    },
    decreaseQuantity: (state, action) => {
      // Decrease the quantity of a specific cart item and remove it if quantity reaches 0
      const cartItem = state.items.find(item => item.cartItemId === action.payload);

      if (cartItem) {
        if (cartItem.quantity > 1) {
          cartItem.quantity -= 1;
        } else {
          // If quantity is 1, remove the cart item from the cart
          state.items = state.items.filter(item => item.cartItemId !== action.payload);
        }
      }
    },
    clearCart: (state) => {
      // Clear the order data from the state (optional)
      state.items = [];
    },
  },
});

export const {
  addToCart,
  increaseQuantity,
  decreaseQuantity,
  clearCart,
} = cartSlice.actions;

export default cartSlice.reducer;



