// pages/CheckoutPage.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import OrderForm from '../components/OrderForm';

const CheckoutPage = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div>
      <header className="fixed top-0 w-full bg-black">
        <nav className="mx-auto py-3.5 flex items-center">
          <button className="text-white text-xl font-bold ml-4 mr-3" onClick={() => navigate(-1)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 inline-block -mt-1 mr-2" // Adjust the size and positioning as needed
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M10 19l-7-7m0 0l7-7m-7 7h18"
              />
            </svg>
          </button>
          <h1 className="text-lg font-bold flex-1 truncate text-center text-white">Dine-in</h1>
          <div className="h-full w-14"></div>
        </nav>
      </header>
      </div>
      <OrderForm />
    </div>
  );
};

export default CheckoutPage;


