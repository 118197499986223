import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getMenuItemByRestaurantCategoryAndName } from '../services/api'; // Import the appropriate API function
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AddToCartContainer from '../components/AddToCartContainer';
import { addToCart } from '../store/cartSlice';

const MenuItemDetail = () => {
  const { restaurant, category, menuItem } = useParams();
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the menu item data based on the restaurant, category, and menuItem
    getMenuItemByRestaurantCategoryAndName(restaurant, category, menuItem) // Use the new API function
      .then((data) => {
        setSelectedMenuItem(data); // The API directly returns the menu item, so no need to find it
      })
      .catch((error) => {
        // Handle any errors that occur during the API request
        console.error("Error fetching menu item:", error);
        // Optionally, you can set an error state or display an error message to the user
      });
  }, [restaurant, category, menuItem]);

  const [quantity, setQuantity] = useState(1);
  console.log(quantity + "hi")
  
  // Handler for increasing the item quantity
  const handleIncrease = () => {
    setQuantity(quantity + 1);
  };

  // Handler for decreasing the item quantity
  const handleDecrease = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  // Handler for adding to cart
  const handleAddToCart = () => {
    // Dispatch addToCart action with selectedMenuItem and quantity
    if (selectedMenuItem) {
      dispatch(addToCart({ ...selectedMenuItem, quantity }));
      setQuantity(1)
    }
  };

  const handleOnClose = () => {
    setQuantity(1)
    navigate(-1);
  }

  return (
    <div>
      {selectedMenuItem && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white w-full h-full mx-auto max-w-screen-sm relative">
            <div className="relative pb-[63%]">
              {/* Close button */}
              <button
              className="z-10 bg-white text-black font-bold rounded-full h-10 w-10 absolute top-4 left-4 flex items-center justify-center"
              onClick={() => navigate(-1)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10 19l-7-7m0 0l7-7m-7 7h18"
                  />
                </svg>
              </button>
              {/* Menu item image */}
              <img
                src={selectedMenuItem.image}
                alt={selectedMenuItem.name}
                className="absolute w-full h-full object-cover"
              />
            </div>
            <div className="p-4">
              {/* Menu item name */}
              <h2 className="text-2xl font-semibold">{selectedMenuItem.name}</h2>
              {/* Menu item description */}
              <p className="mt-1">{selectedMenuItem.description}</p>
              <div className="mt-7 flex justify-between items-center">
                {/* Menu item price */}
                <span className="text-lg font-bold">${selectedMenuItem.price.toFixed(2)}</span>
              </div>
            </div>
            {/* Add to cart container */}
          </div>
        </div>
      )}
    </div>
  );
};

export default MenuItemDetail;


