import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getMenuItemsByRestaurantAndCategory, getRestaurant } from '../services/api';
import MenuItem from '../components/MenuItem';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MenuItemModal from '../components/MenuItemModal';
import { addToCart } from '../store/cartSlice';
import ViewOrderButton from '../components/ViewOrderButton';

const MenuItems = () => {
  const { restaurant, category } = useParams();
  const [menuItems, setMenuItems] = useState([]);
  const [displayName, setDisplayName] = useState('');
  const navigate = useNavigate();
  const [selectedMenuItem, setSelectedMenuItem] = useState(null); // To track the selected menu item
  const [isModalOpen, setIsModalOpen] = useState(false);

  const cartItems = useSelector(state => state.cart.items);
  const totalQuantity = cartItems.reduce((total, item) => total + item.quantity, 0);

  useEffect(() => {
    if (restaurant && category) {
      getRestaurant(restaurant)
      .then(data => {
        setDisplayName(data.displayName);
      })
      getMenuItemsByRestaurantAndCategory(restaurant, category)
        .then((data) => setMenuItems(data))
        .catch((error) => {
          // Handle any errors that occur during the API request
          console.error("Error fetching menu items:", error);
          // Optionally, you can set an error state or display an error message to the user
        });
    }
  }, [restaurant, category]);
  

  const handleViewOrderClick = () => {
    navigate(`/${restaurant}/checkout/dine-in/cart`);
  };

  const handleMenuItemClick = menuItem => {
    setSelectedMenuItem(menuItem);
    setIsModalOpen(true)
    window.history.pushState(null, null, `/${restaurant}/dine-in/${category}/${menuItem.name.toLowerCase().replace(/\s+/g, '-')}`);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    window.history.pushState(null, null, `/${restaurant}/dine-in/${category}`);

  };

  const formattedCategory = category.charAt(0).toUpperCase() + category.slice(1).toLowerCase();

  return (
    <div className="min-h-[86vh] flex flex-col">
      <header className="fixed top-0 w-full bg-yellow-700 z-10">
        <nav className="mx-auto py-3.5 flex items-center">
          <button className="text-white text-xl font-bold ml-4 mr-3" onClick={() => navigate(`/${restaurant}`)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 inline-block -mt-1 mr-2" // Adjust the size and positioning as needed
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M10 19l-7-7m0 0l7-7m-7 7h18"
              />
            </svg>
          </button>
          <h1 className="text-white text-xl font-bold khmer-text">{displayName}</h1>
        </nav>
      </header>
      <h2 className="text-3xl font-semibold mt-16 pt-2 mx-5 mb-4 khmer-text">{formattedCategory}</h2>
      <div className="px-5 grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 pb-20">
        {menuItems.map(menuItem => (
          <MenuItem key={menuItem._id} menuItem={menuItem} onClick={handleMenuItemClick} />
        ))}
      </div>
      {menuItems.length > 0 && (
        <div className="text-center text-sm mt-auto pb-10">
          Powered by Dineboo
        </div>
      )}
      <MenuItemModal
        menuItem={selectedMenuItem}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
      />
    </div>
  );
}  

export default MenuItems;




