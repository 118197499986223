import axios from 'axios';

const BASE_URL = 'https://dineboo.co/api';

const api = axios.create({
  baseURL: BASE_URL,
});

export const getCategoriesByRestaurant = async (restaurant) => {
  try {
    const response = await api.get(`/categories/${restaurant}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getMenuItemsByRestaurantAndCategory = async (restaurant, category) => {
  try {
    const response = await api.get(`/menu/${restaurant}/${category}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getMenuItemByRestaurantCategoryAndName = async (restaurant, category, menuItem) => {
  try {
    const response = await api.get(`/menu/${restaurant}/${category}/${menuItem}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const placeOrder = async (orderData) => {
  try {
    const response = await api.post('/orders/create', orderData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getRestaurant = async (restaurantName) => {
  try {
    const response = await api.get(`/restaurants/${restaurantName}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export default api;
