import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from '../store/cartSlice';
import AddToCartContainer from './AddToCartContainer';

const MenuItemModal = ({ menuItem, isOpen, onClose }) => {
  const dispatch = useDispatch();

  const [quantity, setQuantity] = useState(1);
  
  // Handler for increasing the item quantity
  const handleIncrease = () => {
    setQuantity(quantity + 1);
  };

  // Handler for decreasing the item quantity
  const handleDecrease = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  // Handler for adding to cart
  const handleAddToCart = () => {
    // Dispatch addToCart action with selectedMenuItem and quantity
    if (menuItem) {
      dispatch(addToCart({ ...menuItem, quantity }));
      setQuantity(1)
      onClose(); // Close the modal
    }
  };

  const handleOnClose = () => {
    setQuantity(1)
    onClose();
  }


  if (!isOpen || !menuItem) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white w-full h-full max-w-screen-sm relative">
        <div className="flex relative pb-[62%]">
        <button
            className="bg-white text-black font-bold rounded-full h-10 w-10 absolute top-4 left-4 flex items-center justify-center z-10"
            onClick={handleOnClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M10 19l-7-7m0 0l7-7m-7 7h18"
              />
            </svg>
          </button>
          <img
            src={menuItem.image}
            alt={menuItem.name}
            className="absolute inset-0 object-cover w-full h-full"
          />
        </div>

        <div className="p-4">
          <h2 className="text-2xl font-semibold">{menuItem.name}</h2>
          <p className="mt-1">{menuItem.description}</p>
          <div className="mt-7 flex justify-between items-center">
            <span className="text-lg font-bold">${menuItem.price.toFixed(2)}</span>
          </div>
        </div>

        {/* AddToCartContainer component with updated handlers */}
      </div>
    </div>
  );
};

export default MenuItemModal;










