// store/tableNumberSlice.js
import { createSlice } from '@reduxjs/toolkit';

const tableNumberSlice = createSlice({
  name: 'tableNumber',
  initialState: '',
  reducers: {
    setTableNumber: (state, action) => {
      return action.payload;
    },
  },
});

export const { setTableNumber } = tableNumberSlice.actions;
export default tableNumberSlice.reducer;
