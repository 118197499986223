// store.js
import { configureStore } from '@reduxjs/toolkit';
import cartReducer from './cartSlice';
import tableNumberReducer from './tableNumberSlice';
import orderReducer from './orderSlice'; // Import the orderReducer

const store = configureStore({
  reducer: {
    cart: cartReducer,
    tableNumber: tableNumberReducer,
    order: orderReducer, // Include the orderReducer
  },
});

export default store;


