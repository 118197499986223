import React from 'react';

const MenuItem = ({ menuItem, onClick }) => {
  const { name, description, image, price } = menuItem;

  return (
    <div className="bg-white rounded-lg border flex relative pb-[41%]" onClick={() => onClick(menuItem)}>
      <div className="absolute inset-0 object-cover w-full h-full flex">
        <img src={image} alt={name} className="relative inset-0 object-cover w-2/5 h-full" />
        <div className="flex-1 overflow-hidden p-3">
          <h3 className="text-md font-semibold mb-2 khmer-text">{name}</h3>
          <p className="text-gray-500 mb-2 truncate">{description}</p>
          <div className="text-sm font-bold absolute bottom-3">${price.toFixed(2)}</div>
        </div>
      </div>
    </div>
  );
};

export default MenuItem;

