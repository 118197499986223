// App.js
import React from 'react';
import { Provider } from 'react-redux';
import store from './store/store';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Restaurant from './pages/Restaurant'
import Cart from './pages/Cart';
import MenuItems from './pages/MenuItems';
import CheckoutPage from './pages/CheckoutPage';
import Success from './pages/Success';
import MenuItemDetail from './pages/MenuItemDetail';

const availableRestaurants = [
  {name: 'Dallas', displayName: 'Dallas Restaurant'} 
]; // Define the available restaurant names

const App = () => {
  return (
      <Provider store={store}>
        <Router>
          <Routes>
            {availableRestaurants.map((restaurant) => (
              <Route
                key={restaurant.name}
                path={`/${restaurant.name.toLowerCase()}`}
                element={<Restaurant restaurant={restaurant.name} restaurantDisplayName={restaurant.displayName} coverPhoto={restaurant.coverPhoto}/>}
              />
            ))}
            <Route path="/" element={<Home />} />
            <Route path="/:restaurant/dine-in/:category" element={<MenuItems />} />
            <Route path="/:restaurant/dine-in/:category/:menuItem" element={<MenuItemDetail />} />
            <Route path="/:restaurant/checkout/dine-in/cart" element={<Cart />} />
            <Route path="/:restaurant/checkout/dine-in/payment" element={<CheckoutPage />} />
            <Route path="/:restaurant/checkout/dine-in/confirmation" element={<Success />} /> 
          </Routes>
        </Router>
      </Provider>
  );
};

export default App;


