import React from 'react';

const CategoryList = ({ categories, onSelectCategory }) => {
  return (
    <div className="p-4">
      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-1.5">
        {categories.map((category) => (
          <div
            className="pb-1 cursor-pointer"
            onClick={() => onSelectCategory(category.name)}
            key={category._id}
          >
            <div className="relative pb-[45%]">
              <img
              className="absolute inset-0 object-cover w-full h-full rounded-md"
              src={category.image_url} // Use the correct field name from your database
              alt={category.name}
              />
            </div>
            <p className="text-black text-left mt-2 font-bold khmer-text">{category.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoryList;



