// orderSlice.js
import { createSlice } from '@reduxjs/toolkit';

const orderSlice = createSlice({
  name: 'order',
  initialState: null, // Initialize with null or an empty object
  reducers: {
    saveOrderData: (state, action) => {
      // Save the order data in the state
      return action.payload;
    },
  },
});

export const { saveOrderData, clearOrderData } = orderSlice.actions;

export default orderSlice.reducer;
