// components/OrderForm.js

import React, { useState } from 'react';
import { placeOrder } from '../services/api';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'; // Import useNavigate
import { saveOrderData } from '../store/orderSlice';
import cashIcon from '../assets/icons/payment/money.png'

const OrderForm = () => {
  const { name: savedName, phone: savedPhone } = useSelector(state => (state.order || {}));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [name, setName] = useState(savedName || '');
  const [phone, setPhone] = useState(savedPhone || '');
  const [nameError, setNameError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const { restaurant } = useParams();


  const cartItems = useSelector(state => state.cart.items);
  const tableNumber = useSelector(state => state.tableNumber);

  const totalPrice = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);

  const handleSubmitOrder = async () => {
    // Validate the "Name" and "Phone Number" fields
    if (!name) {
      setNameError('Name is required');
      return;
    }
    if (!phone) {
      setPhoneError('Phone Number is required');
      return;
    }

    // Create an order object with the collected data
    const orderData = {
      name,
      phone,
      tableNumber,
      items: cartItems,
      restaurant,
    };

    try {
      // Send the order to the backend
      dispatch(saveOrderData(orderData));
      const order = await placeOrder(orderData);
      console.log('Order placed:', order);
      navigate(`/${restaurant}/checkout/dine-in/confirmation`);
    } catch (error) {
      console.error('Error placing order:', error);
    }
  };

  return (
    <div className="mt-16 p-4">
      <h2 className="font-semibold text-lg mb-3 pt-1">Your details</h2>

      {/* Input fields for order details */}
      <div>
        <div className="mb-2">
          <label htmlFor="name" className="text-sm font-semibold">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setNameError(''); // Clear the error message
            }}
            className="w-full px-3 py-3 mt-0.5 rounded-md bg-gray-200 focus:outline-none focus:border focus:border-indigo-500 focus:bg-white"
          />
          {nameError && <p className="text-red-500">{nameError}</p>}
        </div>

        <div>
          <label htmlFor="phone" className="text-sm font-semibold">Phone Number</label>
          <input
            type="text"
            id="phone"
            name="phone"
            placeholder="Phone Number"
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
              setPhoneError(''); // Clear the error message
            }}
            className="w-full px-3 py-3 mt-0.5 rounded-md bg-gray-200 focus:outline-none focus:border focus:border-indigo-800 focus:bg-white"
          />
          {phoneError && <p className="text-red-500">{phoneError}</p>}
        </div>
      </div>

      {/* "Select your payment method" section */}
      <div className="mt-9">
        <p className="font-semibold text-lg">Select your payment method</p>
        <div className="mt-2 flex items-center justify-between border border-indigo-800 p-4">
          <label htmlFor="cash" className="flex items-center">
            <img
              src={cashIcon} // Replace with the path to your cash icon
              alt="Cash Icon"
              className="mr-2 h-8 w-8" // Adjust the size as needed
            />
            <span className="text-lg">Cash</span>
          </label>
          <input
            type="radio"
            id="cash"
            name="paymentMethod"
            value="cash"
            checked={true}
            className="h-5 w-5 accent-indigo-800"
          />
        </div>
        {/* Add more payment options here if needed */}
      </div>

      <div className="fixed bottom-0 left-0 w-full bg-white px-4 py-3 shadow-md border">
        <div className="flex justify-between items-center">
          <div className="text-lg font-semibold">Total</div>
          <div className="text-lg font-semibold">${totalPrice.toFixed(2)}</div>
        </div>
        <button
          className="bg-indigo-800 text-white font-bold py-3 px-4 rounded w-full mt-2 font-semibold"
          onClick={handleSubmitOrder}
        >
          Order
        </button>
      </div>
    </div>
  );
};

export default OrderForm;

