import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { setTableNumber } from '../store/tableNumberSlice';
import {
  increaseQuantity,
  decreaseQuantity,
} from '../store/cartSlice';

const Cart = () => {
  const tableNumber = useSelector(state => state.tableNumber);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newTableNumber, setNewTableNumber] = useState('');
  const cartItems = useSelector(state => state.cart.items);
  const { restaurant } = useParams();


  const handleIncreaseQuantity = itemId => {
    dispatch(increaseQuantity(itemId));
  };

  const handleDecreaseQuantity = itemId => {
    dispatch(decreaseQuantity(itemId));
  };

    // Function to open the modal
    const openModal = () => {
      setIsModalOpen(true);
      document.body.style.overflow = 'hidden'; // Disable scrolling
    };
  
    // Function to close the modal and set the new table number
    const closeModal = () => {
      setIsModalOpen(false);
      dispatch(setTableNumber(newTableNumber));
      document.body.style.overflow = 'auto'; // Enable scrolling
    };

    const totalPrice = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);

  return (
    <div className="relative min-h-screen">
      <header className="fixed top-0 w-full bg-black">
        <nav className="mx-auto py-3.5 flex items-center">
          <button className="text-white text-xl font-bold ml-4 mr-3" onClick={() => navigate(-1)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 inline-block -mt-1 mr-2" // Adjust the size and positioning as needed
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M10 19l-7-7m0 0l7-7m-7 7h18"
              />
            </svg>
          </button>
          <h1 className="text-white text-lg font-bold flex-1 truncate text-center text-white">Dine-in</h1>
          <h1 className="h-full w-14"></h1>
        </nav>
      </header>
      {!tableNumber && (
        <div className="sticky mt-14 top-14 bg-white border px-4 py-3 flex justify-between font-semibold">
          Order details
        </div>
      )}
      {!tableNumber ? (
        <div className="border px-4 py-3 flex justify-between mb-2 bg-red-100 text-red-700">
          We need a table number
          <strong>
            <button onClick={openModal}>Add</button>
          </strong>
        </div>
      ) : (
        <div className="pt-16 border px-4 pb-2.5 flex justify-between items-center mb-1.5">
          <div className="font-semibold">
            Table Number
          </div>
          <button className="bg-gray-100 text-black font-semibold rounded px-4 py-1.5" onClick={openModal}>
            Table {tableNumber}
          </button>
        </div>
      )}
      <div className="pb-32">
        {cartItems.length === 0 ? (
          <p className="pt-5 flex justify-center ">Your cart is empty!</p>
        ) : (
          <ul>
            {cartItems.map(item => (
              <li key={item.cartItemId} className="flex justify-between items-center px-4">
                <div className="mt-3">
                  <span className="font-bold text-lg">{item.name}</span>
                  <br />
                  <span>${item.price.toFixed(2)}</span>
                </div>
                <div className="flex items-center">
                  <button onClick={() => handleDecreaseQuantity(item.cartItemId)} className="rounded-full bg-transparent border border-black w-5 h-5 flex items-center justify-center text-black">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-dash" viewBox="0 0 16 16">
                      <path d="M0 8.5a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1H.5a.5.5 0 0 1-.5-.5z"/>
                    </svg>
                  </button>
                  <span className="ml-6 mr-6 text-lg">{item.quantity}</span>
                  <button onClick={() => handleIncreaseQuantity(item.cartItemId)} className="rounded-full bg-transparent border border-black w-5 h-5 flex items-center justify-center text-black">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                      <path d="M7.5.5a.5.5 0 0 1 .5.5V7h6a.5.5 0 0 1 0 1H8v6a.5.5 0 0 1-1 0V8H1.5a.5.5 0 0 1 0-1H7V1a.5.5 0 0 1 .5-.5z"/>
                    </svg>
                  </button>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
      {cartItems.length === 0 ? (
        <div className="fixed bottom-0 left-0 w-full bg-white px-4 py-3 shadow-md border">
          <button
            className="bg-indigo-800 text-white font-bold py-3 px-4 rounded w-full mt-2 font-semibold"
            onClick={() => {
              // Add your navigation logic here to go back to the menu
              navigate(-1); // Replace '/menu' with the actual route for your menu
            }}
          >
            Back to Menu
          </button>
        </div>
      ) : (
        <div className="fixed bottom-0 left-0 w-full bg-white px-4 py-3 shadow-md border">
          <div className="flex justify-between items-center">
            <div className="text-lg font-semibold">Total</div>
            <div className="text-lg font-semibold">${totalPrice.toFixed(2)}</div>
          </div>
          <button
            className={`${
              !tableNumber
                ? 'bg-blue-300 cursor-not-allowed'
                : 'bg-indigo-800'
            } text-white font-bold py-3 px-4 rounded w-full mt-2 font-semibold`}
            onClick={() => {
              // Add your navigation logic here
              navigate(`/${restaurant}/checkout/dine-in/payment`);
            }}
            disabled={!tableNumber}
          >
            Continue
          </button>
        </div>
      )}

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div
            className="fixed inset-0 bg-gray-800 opacity-70"
            onClick={closeModal} // Close modal when clicking on the overlay
          ></div> {/* Gray overlay */}
          <div className="container mx-auto z-50 max-w-lg px-5"> {/* Updated z-index */}
            <div className="bg-white rounded-md border border-gray-300 p-4">
              <div className="flex items-center justify-between mb-2.5">
                <p className="text-lg font-semibold">Where are you seated?</p>
                <button className="text-black" onClick={closeModal}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <hr className="mt-2 mb-2 -mx-4" /> {/* Span the whole container */}
              <p className="text-xs mt-2.5 font-semibold">Table Number</p>
              <input
                type="text"
                placeholder="Table Number"
                value={tableNumber ? tableNumber : newTableNumber}
                onChange={(e) => setNewTableNumber(e.target.value)}
                className="w-full px-3 py-3 mt-1 rounded-md bg-gray-200 focus:outline-none focus:border focus:border-indigo-800 focus:bg-white"
              />
              <div className="mt-4 flex justify-center">
                <button
                  className="bg-indigo-800 font-semibold text-white w-full py-3 rounded-md"
                  onClick={closeModal}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Cart;


