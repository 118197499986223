// src/components/Home.js

import React from 'react';

const Home = () => {
  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="text-center">
        <h1 className="text-3xl font-extrabold mb-4">Welcome to Dineboo!</h1>
        <p className="text-lg mb-4">Get a FREE QR Code Menu</p>
        <a
          href="https://t.me/vantholpich"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-indigo-800 text-white font-semibold py-2 px-9 rounded-full inline-block text-lg transition duration-300 ease-in-out"
        >
          Contact Us
        </a>
      </div>
    </div>
  );
};

export default Home;

